<template>
  <nav :class="$style.container">
    <template v-for="(item, index) in items" :key="item.title">
      <ui-link :to="item.to" :class="$style.link">
        {{ item.title }}
      </ui-link>
      <span v-if="index + 1 !== items.length" :class="$style.link">/</span>
    </template>
  </nav>
</template>

<i18n lang="yaml">
ru:
  clans: Кланы
en:
  clans: Clans
</i18n>

<script setup>
import UiLink from '@/components/ui/link/index.vue'
const { t } = useI18n()

const $props = defineProps({
  nav: {
    type: Array,
    default: () => []
  }
})

const items = computed(() =>
  [
    {
      to: '/clans/rating',
      title: t('clans')
    }
  ].concat($props.nav)
)
</script>

<style lang="scss" module>
.container {
  padding: 2.4em 4em;
  display: flex;
  gap: 0.8em;

  @include down(md) {
    padding: 1.2em 1.6em;
  }
}

.link {
  color: #41435c;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.5;

  &:hover {
    color: lighten(#41435c, 20%);
  }

  &:global(.router-link-active) {
    color: #00c7b1;
  }
}
</style>
